@import '../../styles/colors.scss';

.title {
  color: var(--gp-noir, #181822);
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subTitle {
  color: var(--gp-noir, #181822);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.smallText {
  color: var(--system-color-gris-1-sombre, #686F82);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email {
  padding-top: 48px;
  border-radius: 4px;
  background: var(--system-color-blanc, #FFF);
}