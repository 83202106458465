@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.confirmation-card {
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 50%;
  @media (max-width: $breakpoint-small) {
    width: 90%;
  }

    h2 {
    margin: 0 0 12px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    font-size: 16px;
    gap: 12px;
    margin-top: 8px;

    .action-button {
      padding: 10px 24px;
      font-family:"Lato";
      border: none;
      border-radius: 24px;
      font-weight: 700;
      font-size: inherit;
      display: flex;

      background-color: var(--color-primary);
      color: #fff;
      cursor: pointer;
      &:active {
        background-color: var(--color-primary-dark);
      }
      &:disabled {
        background: $color-disabled;
        cursor: default;
      }
    }

    .cancel-button {
      font-size: inherit;
      font-weight: normal;
      background-color: transparent;
      outline: none;
      border: none;
      color: $color-black;
      cursor: pointer;
      &:active {
        color: var(--color-primary);
      }
    }
  }
}

.confirmation-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
}
