@import "../../styles/colors.scss";
@import "../../styles/breakpoints";


.adhesion-step-signature {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 50px 16px;
  box-sizing: border-box;


  @media (max-width: $breakpoint-small) {
    margin: 40px 0;
    width: 100%;
  }


  .bold-values {
    font-weight: 900;
    padding: 0 2px;
  }


  & .loading-link-signature {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__signButton {
    justify-content: center;
    width: 100%;
    margin: 24px auto;

    & > button {
      margin : 0 auto;
    }
  }

  &__card {
    background-color: white;
    border-radius: 5px;
    margin: 0;
    margin-top: 50px;
    box-shadow: 0 1px 15px #BABED44D;

    & > .header {
      font-family: "Lato";
      border-bottom: 1px solid $grey-border-light;
      & p {
        white-space: nowrap;
        @media (max-width: $breakpoint-small) {
          white-space: normal;
          line-height: normal;
          text-align: center;
        }
      
      }
      & > div {
        margin : 0 auto;
      }
      padding: 16px;
      margin: 0;
      background-color: $bleu-light-transp;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
    }

    & > .content {
      padding: 32px;

      @media (max-width: $breakpoint-small) {
        padding: 24px;
      }
      & .recap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: "Lato";

        small {
          font-size: 16px;
        }

        &__row {
          margin: 8px 0;
          display: flex;
          flex-direction: row;
          align-items: baseline;
          width: 100%;

          &__title {
            font-weight: bold;
            min-width: 30%;
          }

          &__content {

          }
        }
      }

      & .detail {
        font-family: "Lato";
        padding-right: 64px;

        @media (max-width: $breakpoint-small) {
          padding-right: 0;

        }
        & > .detail__assureur, .detail__date {
          margin-right: 24px;
          line-height: normal;
          text-align: center;

          @media (max-width: $breakpoint-small) {
            margin-right: 0;

          }
    
        }
      }

      & > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .next-button-container {
    margin: 50px auto;
    width: fit-content;
}
}