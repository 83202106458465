@import 'styles/breakpoints';

.title-description-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & svg {
    width: 80px;
    margin-bottom: 32px;
    margin-right: 16px;
    font-size: 30px;
  }
  @media (max-width: $breakpoint-large) {
    & h2 {
      padding: 0;
    }
    & svg {
      display: none;
    }
  }
}

.separator {
  border: none;
  border-top: 1px solid rgba(194, 194, 194, 0.5);
  margin: 48px 48px 32px;
  @media (max-width: $breakpoint-large) {
    margin: 24px;
  }
}

.description-step {
  margin: 0;
}

.before-title {
  margin-top: 32px;
}
