.formMandatSepaTitulaireCompte {
  .formMandatSepaTitulaireCompte__title {
    margin-bottom: 48px;
  }

  .modification__information {
    margin-bottom: 32px;
  }

  .layout-field-container > *:nth-child(2n) {
    margin-left: 16px;
  }

  .layout-field-container > *:not(:nth-child(2n)) {
    margin-left: 0px;
  }
}
