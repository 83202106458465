@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputRadioButtonIcon {
  @include groupField();

  margin-bottom: 14px;
}

.InputRadioButtonIcon__label {
  @include label();

  height: 100%;

  & > input {
    opacity: 0;
    position: absolute;
  }
}

.InputRadioButtonIcon__group {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -8px;
  margin-right: -8px;

  &.-noWrap {
    flex-wrap: nowrap;
  }

  .InputRadioButtonIcon.InputRadioButtonIcon.-small & {
    @media (min-width: $breakpoint-medium) {
      flex-wrap: nowrap;
    }
  }
}

.InputRadioButtonIcon__layout {
  margin-bottom: 16px;
  padding: 0 8px;
  width: 50%;

  @media (max-width: $breakpoint-xs) {
    width: 100%;
  }
}

.InputRadioButtonIcon__button {
  @include field();

  display: block;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 40px 45px;
  text-align: center;

  .InputRadioButtonIcon.InputRadioButtonIcon.-small & {
    padding: 15px 0px;
  }

  &:hover {
    @include hover();
    color: var(--color-primary);
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-primary);
  }

  &:focus {
    @include focus();
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-primary);
  }

  input:checked + & {
    @include checked();
    border-color: var(--color-primary);
    box-shadow: 0 0 0 2px var(--color-primary);
  }
}

.InputRadioButtonIcon__icon {
  margin-bottom: 15px;

  path {
    fill: var(--color-secondary);
  }

  .InputRadioButtonIcon__button:hover & path {
    fill: var(--color-primary);
  }

  .InputRadioButtonIcon__button.-selected & path {
    fill: var(--color-primary);
  }

  .InputRadioButtonIcon__button.-error & path {
    fill: $color-error;
  }
}
