@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';


.inputDatePicker__tooltip {
  margin-left: 8px;
}

.input-date {
  @include groupField();
  width: auto;

  @media (max-width: $breakpoint-medium) {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  label {
    @include label();
  }

  input {
    @include field();
  }
}

.calendar-month {
  button {
    margin: 0;

    &:focus {
      outline: none;
    }

    &:active {
      background-color: transparent;
      filter: brightness(50%);
    }
  }

  .react-datepicker__navigation {
    margin-top: 10px;
    padding: 0;

    &.react-datepicker__navigation--previous {
      border-right-color: white;
    }

    &.react-datepicker__navigation--next {
      border-left-color: white;
    }
  }

  .react-datepicker__month-container {
    .react-datepicker__current-month {
      margin: 10px 0px 10px 0;
      color: white;
    }

    .react-datepicker__header {
      background-color: var(--color-primary);
      padding-top: 10px;
    }

    .react-datepicker__day-name {
      color: white;
    }

    .react-datepicker__month-select {
      border-radius: 3px;
      padding: 3px;
    }

    .react-datepicker__year-select {
      border-radius: 3px;
      padding: 3px;
    }

    .react-datepicker__day--selected {
      background-color: var(--color-primary);

      &:hover {
        background-color: var(--color-primary);
      }
    }

    .react-datepicker__day--keyboard-selected {
      background-color: var(--color-primary);
    }
  }
}

.calendar-year {
  .react-datepicker__header {
    line-height: 50px;
    height: 50px;
    background-color: var(--color-primary);
    color: white;
    padding: 0;
  }

  .react-datepicker__year-text {
    padding: 10px;
  }

  .react-datepicker__year-wrapper {
    justify-content: center;
  }

  .react-datepicker__navigation {
    &.react-datepicker__navigation--previous {
      border-right-color: white;
    }

    &.react-datepicker__navigation--next {
      border-left-color: white;
    }
  }

  button {
    margin: 0;
    padding: 0;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &:active {
      background-color: transparent;
      filter: brightness(50%);
    }
  }
}

.input-error {
  color: $color-error;

  input {
    border-color: $color-error;
  }
}
