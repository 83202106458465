@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/field.scss';

.inputText {
  @include groupField();
}

.inputText__label {
  @include label();
}

.inputText__sublabel {
  @include label();

  font-size: 14px;
  margin-top: -15px;
  font-weight: normal;
}

.inputText__tooltip {
  margin-left: 8px;
}

.inputText__field {
  @include field();
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  @include field();

  .-error & {
    @include inputError();
  }
}

.react-autosuggest__input--focused {
  @include focus();
}

.react-autosuggest__input--focused:hover,
.-error .react-autosuggest__input--focused:hover {
  @include hover();
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid var(--color-primary-light);
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 360px;
  overflow-x: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  color: $color-black;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $color-light-background;
}

.react-autosuggest__suggestion-match {
  color: var(--color-primary);
  font-weight: 900;
}
