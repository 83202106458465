@import "../../styles/colors.scss";

.button-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color : $color-black;
  background-color: white;
  border-radius: 10px;


  &.primary {
    background-color: var(--color-primary);
    color : white;
    border: none;
  }

  &.secondary {
    background-color: var(--color-secondary-light);
    border: none;
    color: var(--color-secondary);
  }
}