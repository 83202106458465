@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputDate {
  @include groupField();

  max-width: 350px;

  &.-fullWidth {
    max-width: $max-input-size;
  }
}

.InputDate__sep {
  position: relative;
  display: block;
  width: 0;
  margin-left: -3px;
}

.InputDate__label {
  @include label();
}

.InputDate__fieldGroup {
  @include field();

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.InputDate__day,
.InputDate__month,
.InputDate__year {
  @include clear-input();

  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;

  &::placeholder {
    @include placeholder();
  }
}

.InputDate__day {
  width: 100%;
}

.InputDate__month {
  width: 100%;
}

.InputDate__year {
  width: 100%;
}
