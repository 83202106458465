.button-details-garanties {
    flex-basis: 100%;

    & .button-details-garanties__wrapper {
        flex-basis: 100%;
        width: 100%;

        & .columns-1 {
            width: 30% !important;
        }

        & .columns-2 {
            width: calc(60% + 16px) !important;
        }

        & .columns-3 {
            width: calc(90% + 32px) !important;
        }
    }
}