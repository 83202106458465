@import "styles/colors";
@import "../../styles/breakpoints.scss";


.title-with-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 12px 0;
  padding: 14px 0;

  @media (max-width: $breakpoint-small) {
    padding: 6px 0;

  }


  & > svg {
    margin-right: 15.5px;
    color : var(--color-secondary);
    margin: auto 8px;
    @media (max-width: $breakpoint-small) {
      height: 24px;
    }
  }
  & > h1,h2,h3,h4,h5,h6 {
    flex: 3;
    margin: auto;
  }
}