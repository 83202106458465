$color-light-background: #e9ecef;
$color-light-error-background: #dba9ae;
$color-error: #d10000;
$color-delete: #ea6863;
$color-delete-hover: #e9413c;
$color-black: #212129;
$faux-noir: #181822;
$grey-light: #deeaf0;
$green-light: #597c3c;
$bleu-light-transp: #15606d10;
$bleu-light-opac: #15606d;
$grey: #707070;
$grey-border-light: #e3e3e3;
$color-grey-medium: #92939b;
$color-disabled: #b5bfc1;
