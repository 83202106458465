@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/field.scss';

.Infraction {
  position: relative;
  width: 100%;
  max-width: $max-input-size;
  margin-bottom: 32px;
  border: solid 1px $grey-border-light;
  border-radius: 6px;
  padding: 20px;
  padding-top: 60px;

  &:empty {
    padding-top: 20px;
    cursor: pointer;

    &::after {
      content: attr(data-empty);
    }
  }
}

.Infraction__remove {
  @include clear-button();
  color: $color-delete;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
}
