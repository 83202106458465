@import "styles/colors.scss";
@import "../../styles/breakpoints.scss";



.breadcrumb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 900;
  align-items: center;
  flex-shrink: 0;
  margin: 32px 0 16px 0;
  @media screen and (max-width: $breakpoint-small) {
    font-size:65%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2px 0;
  }

  svg {
    color: $color-grey-medium;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin : 0 16px;
    color :  $color-grey-medium;

    @media screen and (max-width: $breakpoint-small) {
      margin : 0 8px;
    }


    &.activeNonComplet {
      color : var(--color-secondary);
      & .bubble {
        color : white;
        background-color:var(--color-secondary);
        border: none;
      }

      & p {
        color : var(--color-secondary);
        font-size: 18px;

        @media screen and (max-width: $breakpoint-small) {
          font-size: 15px;
        }
      }
    }

    &.activeComplete {
      color : var(--color-secondary);
      & .bubble {
        color : white;
        background-color: var(--color-secondary);
        border: none;
      }

      & p {
        color : var(--color-secondary);
        font-size: 18px;

        @media screen and (max-width: $breakpoint-small) {
          font-size: 15px;
        }
      }
    }

    &.nonActiveComplete {
      color : $faux-noir;
      & .bubble {
        display: flex; /* or inline-flex */
        align-items: center;
        justify-content: center;
        width: 24px;  
        height: 24px; 
        border-radius: 50%;
        border: 1px solid $faux-noir;
        line-height: 1;
        text-align: center;
        vertical-align: center;
        padding : 2px;
        @media screen and (max-width: $breakpoint-small) {
          width: 20px;  
          height: 20px; 
    
        }
      }

      & p {
        color : $faux-noir !important
      }
    }

    & .bubble {
      display: flex; /* or inline-flex */
      align-items: center;
      justify-content: center;
      width: 24px;  
      height: 24px; 
      border-radius: 50%;
      border: 1px solid $color-grey-medium;
      line-height: 1;
      text-align: center;
      vertical-align: center;
      padding : 2px;
      @media screen and (max-width: $breakpoint-small) {
        width: 20px;  
        height: 20px; 
  
      }
    }

    & p {
      margin : 0 16px;
      font-size: 12px;

      @media screen and (max-width: $breakpoint-small) {
        font-size: 10px;
        margin : 0 8px;

      }
    
    }
  }
}