@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputCheckboxButton {
  display: flex;
  gap: 14px;
  align-items: center;
  width: calc(100%);

  &.-column-1 {
    width: calc(100%);
  }

  &.-column-2 {
    width: calc(50% - 7px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &.-column-3 {
    width: calc(33.33% - 10px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &.-column-4 {
    min-width: 0px;
    width: calc(25% - 11px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
}

.InputCheckboxButton__label {
  display: block;
  width: 100%;
}

.InputCheckboxButton__button {
  @include field();

  display: block;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  .InputCheckboxButton__input:checked + & {
    @include checked();
  }

  .InputCheckboxButton__input:focus + & {
    @include focus();
  }

  &.-noCenterLabel {
    text-align: left;
  }
}

.InputCheckboxButton__checkmark {
  display: none;
  position: absolute;
  margin-left: 7px;
  width: 10px !important;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);

  path {
    fill: var(--color-primary);
  }

  .InputCheckboxButton__input:checked + .InputCheckboxButton__button & {
    display: inline-block;
  }
}

.InputCheckboxButton__input {
  position: absolute;
  opacity: 0;
}
