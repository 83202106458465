@import '../../styles/colors.scss';
@import '../../styles/breakpoints.scss';

.breadcrumb {
  text-transform: uppercase;
  font-family: 'Lato';
}

.loader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(255, 255, 255, .7);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader div {
  position: relative;
  top: 10%;
  font-family: Manrope, serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.adhesion-step-offre {
  &__title {
    margin-top: 60px;
    text-align: center;

    @media (max-width: $breakpoint-small) {
      margin-top: 40px;
    }
  }

  &__containerCompare {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 315px;

    & > .header-compare-mobile {
      display: none;
      @media (max-width: $breakpoint-small) {
        justify-content: space-around;
        width: 70%;
        margin-left: 15%;
        display: flex;
        & button {
          background: transparent;
          border: none;

          & svg {
            color: $grey;
          }

          &:disabled {
            & svg {
              color: $grey-border-light;
            }
          }
        }
      }

      .product-number {
        margin: auto 0;
      }
    }

    // GENERAL STYLE FOR ROWS
    & > div[class^='row-'] {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 1em 0;
      font-family: 'Lato';

      &.emptyTitle {
        margin-left: 20%;
        @media (max-width: $breakpoint-small) {
          margin-left: 15%;
        }
      }

      & > .title {
        width: 20%;
        @media (max-width: $breakpoint-small) {
          width: 30%;
        }
        flex-shrink: 0;

        & > h3 {
          font-family: 'Lato';
          font-weight: 900;
          overflow-wrap: anywhere;
        }

        @media (max-width: $breakpoint-small) {
          & * {
            flex-shrink: 1;
            white-space: pre-wrap !important;
          }
        }
      }

      button {
        font-family: 'Lato';
      }

      .item-container {
        margin: 8px;
      }

      & > .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-flow: wrap;
        align-items: stretch;
        flex-basis: 100%;
        @media (max-width: $breakpoint-small) {
          overflow-x: hidden;
          flex-flow: nowrap;
        }

        & > .item {
          max-width: 30%;
          transition: transform 0.5s;
          box-sizing: border-box;
          width: 100%;
          font-weight: 900;
          margin: 0 8px;
          @media (max-width: $breakpoint-small) {
            max-width: 70%;
            width: 70%;

            margin: 0 15%;
            flex-shrink: 0;
            transform: translate(0, 0);
            &__prec {
              &._1 {
                transform: translate(-284%, 0);
              }

              transform: translate(-142%, 0);
            }
            &__next {
              transform: translate(142%, 0);
            }

            &.mobile-displayed {
              &__0 {
                transform: translate(0, 0);
              }

              &__1 {
                transform: translate(-142%, 0);
              }

              &__2 {
                transform: translate(-284%, 0);
              }
            }
          }
        }
      }
    }

    // SPECIFIC STYLE FOR EACH ROW

    // ROW RECOMMANDATION
    & > .row-recommandation {
      width: 80%;
      margin-bottom: 0 !important;

      @media (max-width: $breakpoint-small) {
        width: 70%;
      }

      & > .content > .row-recommandation__info {
        width: 100%;
        box-sizing: border-box;
        padding: 8px;
        text-align: center;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: calc(50% - 8px);
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;

          border-top: 4px solid var(--color-primary-transparent);
        }
      }
    }

    // ROW PRODUCT
    & > .row-product {
      width: 80%;

      .product__name {
        font-weight: 900;
      }

      @media (max-width: $breakpoint-small) {
        width: 70%;
      }

      & > .content > .row-product__name {
        width: 100%;
        margin: 8px;
        box-sizing: border-box;
        padding: 8px;
        text-align: center;
      }
    }

    // ROW ASSUREUR
    & > .row-assureur {
      width: 100%;

      & > .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        @media (max-width: $breakpoint-small) {
          width: 70%;
        }

        word-break: break-all;

        & .card {
          width: 100%;
          margin: 8px;
          padding: 8px;
          box-sizing: border-box;
          color: $faux-noir;
          border: 1px solid $grey-light;

          & > img {
            width: 50px;
            margin-right: 20px;
          }
        }
      }

      & > .title > h3 {
        margin: 0;
      }
    }

    // ROW GUARANTEES
    & > .row-guarantees {
      width: 100%;
      align-items: flex-start !important;

      & .container-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      & .quote-column {
        display: flex;
        flex-direction: column;
      }

      & .title > h3 {
        margin-bottom: 0;
        height: 50px;
      }

      & > .title .codifContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 8px 0;
        height: 40px;

        & > h5 {
          flex-shrink: 1;
          font-weight: 400;
        }

        & > hr {
          flex-grow: 1;
          height: 1px;
          border: 0;
          margin: 0;
          background-color: $grey-light;
        }
      }

      & .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        @media (max-width: $breakpoint-small) {
          width: 70%;
        }

        align-self: flex-start;
        margin-top: 50px !important;

        & > div {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          padding: 0 8px;

          & > div > button {
            margin: 12px 0;
            font-weight: 700;
            width: 100%;
            color: var(--color-primary);
            background-color: var(--color-primary-transparent);
            padding: 16px;
            border-radius: 16px;

            & > svg {
              margin-right: 8px;
            }

            &:after {
              width: 100;
              height: 100%;
              background-color: red;
            }
          }

          & .rowBar {
            display: flex;
            align-items: center;
            height: 40px;
            margin: 8px 0;
          }
        }
      }
    }

    // ROW DOC
    & > .row-documentation {
      width: 100%;

      & > .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        @media (max-width: $breakpoint-small) {
          width: 70%;
        }

        align-items: center;

        & .proposition {
          margin: 0 0 8px;
          min-height: 0;
        }

        & > .list-documents {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
          width: 100%;
          padding-left: 40px;

          & a {
            display: inline-block;
            font-weight: bold;

            & > svg {
              width: 18px;
            }
          }
        }
      }
    }

    // ROW COST
    & > .row-cost {
      width: 100%;

      .cost-month {
        font-weight: 400;
      }

      & > .title h3 {
        margin: 0;
      }

      .montant-label {
        margin: auto;
        font-weight: 900;

        small {
          font-size: 16px;
        }
      }

      & > .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        @media (max-width: $breakpoint-small) {
          width: 70%;
        }

        align-items: center;

        & > .card {
          margin: 8px;
        }
      }
    }

    // ROW FRAIS COST
    & > .row-frais-cost {
      width: 100%;

      .montant-label {
        margin: auto;
        font-weight: 900;
      }

      & > .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        @media (max-width: $breakpoint-small) {
          width: 70%;
        }

        align-items: center;

        & > .card {
          margin: 8px;
        }
      }

      & .frais__general {
        flex-basis: 100%;
      }
    }

    // ROW ACTIONS
    & > .row-actions {
      width: 80%;
      @media (max-width: $breakpoint-small) {
        width: 70%;
      }

      justify-content: space-around !important;

      & .content {
        flex-wrap: wrap;
        @media (max-width: $breakpoint-small) {
          flex-wrap: nowrap;
        }
      }

      & .button-choice {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 52px;
        border-radius: 24px;
        margin: 0 auto;

        & svg {
          margin-left: 4px;
        }

        &.canceled {
          background-color: gray;
          color: white;
          cursor: default;
        }

        &.choosen {
          cursor: default;
        }
      }

      & .buttons {
        position: relative;
        margin: 8px;

        @media (max-width: $breakpoint-small) {
          margin: 0;
          width: 100%;
          max-width: unset;
        }
      }

      & .submit_error {
        width: 100%;
        bottom: 8px;
        color: $color-error;
        text-align: center;

        & small {
          float: none;
        }
      }

      & .conseil__favori {
        margin-top: 20px;
        margin-bottom: 8px;
        padding-bottom: 16px;
        width: 100%;
        position: relative;
        // @media (max-width: $breakpoint-small) {
        //   flex-wrap: nowrap;
        //   width: 200%;
        //   margin-left: -50%;
        // }
        &:before {
          content: '';
          position: absolute;
          top: -8px;
          left: calc(50% - 12px);
          width: 0;
          border-bottom: 8px solid;
          border-bottom-color: var(--color-secondary);
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
        }
        &:after {
          content: '';
          position: absolute;
          top: -7px;
          left: calc(50% - 12px);
          width: 0;
          border-bottom: 8px solid;
          border-bottom-color: #fff;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
        }
      }

      & .conseil__general {
        margin: 24px 8px 8px 8px;
        flex-basis: 100%;
      }

      & .container-actions.content .buttons {
        @media (max-width: $breakpoint-small) {
          width: 100%;
          max-width: unset;
          margin: 0;
          &.mobile-displayed {
            &__0 {
              transform: translate(0, 0);
            }

            &__1 {
              transform: translate(-100%, 0);
            }

            &__2 {
              transform: translate(-200%, 0);
            }
          }
        }
      }
    }
  }

  .first-row {
    overflow-wrap: anywhere;
  }

  .next-button-container {
    @media (max-width: $breakpoint-small) {
      width: 100%;
      margin: 30px 0;
      justify-content: center;
    }
  }
}

@mixin stylingFormAdhesionOffre($item-height) {
  .row-product__name {
    min-height: $item-height;
    height: auto;
  }

  .card {
    margin: 8px;
    min-height: $item-height;
    height: auto;
  }
}

.form-adhesion {
  @include stylingFormAdhesionOffre($item-height: 64px);

  @media screen and (max-width: $breakpoint-large-xl) {
    @include stylingFormAdhesionOffre($item-height: 60px);
  }

  @media screen and (max-width: $breakpoint-large-m) {
    @include stylingFormAdhesionOffre($item-height: 60px);
  }

  @media screen and (max-width: $breakpoint-large) {
    @include stylingFormAdhesionOffre($item-height: 60px);
  }

  @media screen and (max-width: $breakpoint-medium-m) {
    @include stylingFormAdhesionOffre($item-height: 55px);
  }
}
