@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";

$margin-general: 56px;

.marged-block {
  margin-left: $margin-general;
}

.marged-block-with-icon {
  margin-left: calc(#{$margin-general} - 8px);

  .link-with-icon {
    font-family: 'Open Sans';
  }
}


.description-label {
  margin: 0.5em 0 1.625em 0;
  font-family: 'Lato';
  font-weight: 400;
}

.form-init-title {
  padding-left: 8px;
  font-weight: 900;
}

.next-button-container {
  margin-left: $margin-general;
  @media (max-width: $breakpoint-small) {
    margin-left: 0;
    justify-content: center;
    display: flex;
  }
}

.step-title {
  font-family: "Lato";
}

.green {
  color: $green-light !important
}

.red {
  color: $color-error !important
}