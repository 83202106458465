@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputCheckboxInline {
  @include groupField();
}

.InputCheckboxInline__label {
  @include label();

  position: relative;
}

.InputCheckboxInline__group {
  display: flex;
  gap: 14px;
  align-items: center;
}

.InputCheckboxInline__container {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.InputCheckboxInline__button {
  @include field();

  cursor: pointer;
  width: auto;
  // min-width: 150px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0px;
  text-align: center;

  & > input {
    opacity: 0;
    position: absolute;
  }

  &.-selected {
    @include checked();
  }

  &.-noCenterLabel {
    text-align: left;
  }

  &.-fullWidthLabel {
    width: 100%;
  }

  &.-column-1 {
    width: calc(100%);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &.-column-2 {
    width: calc(50% - 7px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &.-column-3 {
    width: calc(33.33% - 10px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &.-column-4 {
    min-width: 0px;
    width: calc(25% - 11px);
  }

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.InputCheckboxInline__checkmark {
  position: absolute;
  margin-left: 7px;
  width: 10px !important;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);

  path {
    fill: var(--color-primary);
  }
}
