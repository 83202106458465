.formMandatSepaSignature {
  .formMandatSepaSignature__justify {
    text-align: justify;
  }

  .formMandatSepaSignature__title {
    margin: 48px 0;
  }

  .formMandatSepaSignature__paddingTop30 {
    padding-top: 30px;
  }


  .formMandatSepaSignature__creancier {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .formMandatSepaSignature__text {
    margin-bottom: 35px;
  }


  .formMandatSepaSignature__submit {
    margin-top: 8px;
  }

  .formMandatSepaSignature__waitingProcedureSignature {
    border-color: var(--color-primary) !important;
    color: var(--color-primary);
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 16px;

  }
}
