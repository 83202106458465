@import 'styles/colors.scss';
@import 'styles/layout.scss';

.icon-info-bulle {
  color: var(--color-primary-complementary);
}

.text-infobulle {
  line-height: 24px !important;
  max-width: 80vw;

  @media (min-width: $breakpoint-medium) {
    max-width: 50vw;
  }
}
