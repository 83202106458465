@import "../../styles/colors.scss";
@import "../../styles/breakpoints";

.adhesion-step-infos {

  margin : 50px 16px;
.file-input-container{
  margin: 50px 0;
  width: 50%;
  min-width: 400px;
  border-bottom: inset;
  padding-bottom: 12px;

  @media (max-width: $breakpoint-medium-s) {
    width: 75%;
    min-width: 0;

  }

  @media (max-width: $breakpoint-small) {
    width: 100%;
    margin: 30px 0;
  }


  .file-input {
    margin : 24px 0;
    font-family: 'Lato';
    
  }
}

    .error-label {
      color: $color-error;
      float: left;
      margin: 0;
    }
  

  .input-container{
    margin: 12px 0;
    width: 50%;
    min-width: 400px;
    font-family: 'Lato';
    font-weight: 400;
    .error-label {
      float: right;
      color : $color-error;
      margin-top: -8px;
    }


    @media (max-width: $breakpoint-medium-s) {
      width: 75%;
      min-width: 0;
  
    }
  
    @media (max-width: $breakpoint-small) {
      width: 100%;
      margin: 30px 0;
    }

  }

  .label-infos {
    font-weight: normal;
    font-family: "Lato";
    font-weight: 400;
    padding: 12px 0;
    display: block;
  }

  .form-consentement-container{
    margin: 40px 0 8px 0;
  }

  .next-button-container{
    width: 50%;
    display: flex;
    margin: 0;

    @media (max-width: $breakpoint-medium-s) {
      width: 75%;
      min-width: 0;
  
    }
  
    @media (max-width: $breakpoint-small) {
      width: 100%;
      margin: 30px 0;
      justify-content: center;

    }
  }

  .num-secu-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      all: unset;
      cursor: pointer;
      margin-left: 12px;
      color: var(--color-primary);
    }
  }
}
