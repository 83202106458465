@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';

.checkbox {
  display: inline-block;
  & .error-label {
    margin-top: 0;
  }
}

.container-radios {
  max-width: $max-input-size;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & .input-radio-button {
    width: 130px;
    text-align: center;
  }

  @media (max-width: $breakpoint-small) {
    & .input-radio-button {
      width: 100%;
      text-align: center;
    }
    .axe-info-bulle {
      display: none;
    }
    .radio-group-label > .link-infobulle {
      display: inline-block;
    }
  }
}

.radio-group-label {
  margin-bottom: 16px;
  & > .link-infobulle {
    display: none;
    margin-left: 8px;
  }
}

.icon {
  margin-right: 10px;
}

.axe-info-bulle {
  margin-top: 10px;
  padding-left: 8px;
}

.checkbox-container {
  margin-top: 8px;
  width: 100%;
}

.container-autre-besoins {
  margin-top: 32px;
}

.container-inputs-axe {
  margin-top: 48px;
}

.AxeField__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}
