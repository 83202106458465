@import "../../../styles/colors.scss";


  .row-frais-cost{

    .bloc-frais{
        width: 100%;  
        box-sizing: border-box;


        &.columns-1 {
            max-width: calc(24%);
            margin-right: 25%;
        }
        &.columns-2 {
            max-width: calc(50%);
       
        }
        &.columns-3 {
            max-width: calc(75%);
      
        }
    }

}