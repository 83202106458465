@import "styles/breakpoints";

#left-pane {
  position: fixed;
  box-sizing: border-box;
  height: 100%;
  background-color: var(--color-primary);
  color: #fff;
  letter-spacing: 0;
  padding: 64px 32px 32px 80px;
  width: 500px;

  & .link-courtier {
    & a {
      display: inline-block;
      text-decoration: none;
      color: white;
      margin-bottom: 32px;
      display: inline-block;
    }
  }
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 96px;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 16px;
}
.logo {
  height: 80px;
  max-width: 80px;
  object-fit: contain;
}
.left-pane-title {
  margin-bottom: 48px;
  &__mobile {
    margin-bottom: 16px;
  }

  &__mobile p {
    font-size: 24px;
  }
}
.left-pane-title,
.left-pane-title * {
  font-size: 32px;
  font-weight: 900;
  line-height: 38px;
  word-break: break-word;
}
.left-pane-subtitle,
.left-pane-subtitle * {
  font-size: 18px;
}

.fiche-download,
.fiche-download * {
    color: white;
  margin-top: -10%;
  padding: 0 0 15px 0;
}

@media (max-width: $breakpoint-medium) {
  #left-pane {
    display: none;
  }
}

@media (max-width: $breakpoint-medium-s) {
  #left-pane {
    width: 250px;
  }
}

@media (max-width: $breakpoint-large) {
  #left-pane {
    width: 300px;
    padding: 32px;
    .left-pane-title {
      margin-bottom: 32px;
    }
    .left-pane-title,
    .left-pane-title * {
      font-size: 24px;
      line-height: 24px;
    }
  }
}
