@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

/* button reset */
@mixin clear-button() {
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  outline: none;

  &:focus-visible {
    outline: var(--primary) solid 2px;
    outline-offset: 3px;
    outline-style: auto;
  }
}

@mixin groupField {
  position: relative;
  width: 100%;
  max-width: $max-input-size;
  margin-bottom: 30px;
}

@mixin label {
  font-size: 16px;
  display: block;
  font-weight: bold;
  margin: 0 0 8px 0;
  margin-bottom: 15px;

  &.-error {
    @include inputError();
  }
}

@mixin field {
  position: relative;
  width: 100%;
  padding: 12px 10px;
  border: 1px solid $color-grey-medium;
  font-size: 16px;
  border-radius: 6px;
  transition: box-shadow 300ms ease, border-color 100ms ease;

  &:focus {
    @include focus();
  }

  &:checked {
    @include checked();
  }

  &::placeholder {
    @include placeholder();
  }

  &.-error {
    @include inputError();
  }

  &:hover {
    @include hover();
  }

  &:disabled {
    @include disabled();
  }
}

@mixin disabled() {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: none;
  border: 1px solid $color-grey-medium;
  color: $color-grey-medium;
}

@mixin hover() {
  outline: none;
  box-shadow: 0 0 0 1px var(--color-primary);
  border-color: var(--color-primary);
}

@mixin focus() {
  outline: none;
  box-shadow: 0 0 0 0px var(--color-primary);
  border-color: var(--color-primary);
}

@mixin checked() {
  outline: none;
  box-shadow: 0 0 0 0px var(--color-primary);
  border-color: var(--color-primary);
  background-color: var(--color-primary-transparent);
}

@mixin placeholder() {
  color: $color-grey-medium;
}

@mixin inputError() {
  color: $color-error;
  border-color: $color-error;
}

@mixin clear-input {
  border: none;
  vertical-align: top;
  font-family: inherit;
  text-align: inherit;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
