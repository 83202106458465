@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputImmatriculation {
  @include groupField();
}

.InputImmatriculation__label {
  @include label();
}

.InputImmatriculation__fieldGroup {
  @include field();

  font-size: 24px;

  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  font-weight: 900;
  max-width: 280px;

  padding-left: 34px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYWxxdWVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgMzAgNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZT4uc3Qxe2ZpbGw6I2ZmZn08L3N0eWxlPjxwYXRoIGQ9Ik0wIDBoMzB2NTBIMFYweiIgZmlsbD0iIzJjNWJkMSIvPjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0xNC45IDExbC0uMi41LS41LjEuNC40LS4xLjUuNC0uMi40LjItLjEtLjUuNC0uNC0uNS0uMS0uMi0uNXptLTMuNC45bC40LjMuNS0uMi0uMS41LjMuNGgtLjVsLS4zLjQtLjItLjUtLjUtLjEuNC0uM3YtLjV6bS0yLjYgMi42aC41bC4zLS40LjEuNS41LjItLjQuMnYuNWwtLjQtLjMtLjUuMS4yLS41LS4zLS4zem0tLjQgMy4ybC0uNS4yLjUuMi4xLjUuNC0uNC41LjEtLjItLjQuMi0uNC0uNS4xLS40LS40LS4xLjV6bS40IDMuN2wuMy0uNC0uMi0uNS41LjEuNC0uM3YuNWwuNC4zLS41LjItLjEuNS0uMy0uNGgtLjV6bTIuNiAyLjV2LS41bC0uNC0uMy41LS4xLjItLjUuMy40aC41bC0uMy40LjEuNS0uNS0uMi0uNC4zem0zLjQtLjJsLS40LS4yLjEuNS0uNC40LjUuMS4yLjUuMi0uNS41LS4xLS40LS40LjEtLjUtLjQuMnptMy41LjJsLS40LS4zLS41LjIuMS0uNS0uMy0uNGguNWwuMy0uNC4yLjUuNS4xLS40LjN2LjV6bTIuNS0yLjVoLS41bC0uMy40LS4xLS41LS41LS4yLjQtLjN2LS41bC40LjMuNS0uMS0uMi41LjMuNHptMC0zLjlsLS41LS4xLjIuNC0uMi40LjUtLjEuNC40LjEtLjUuNS0uMi0uNS0uMi0uMS0uNS0uNC40em0wLTNsLS4zLjQuMi41LS41LS4xLS40LjN2LS41bC0uNC0uMy41LS4yLjEtLjUuMy40aC41em0tMi41LTIuNnYuNWwuNC4zLS41LjEtLjIuNS0uMy0uNGgtLjVsLjMtLjQtLjEtLjUuNS4yLjQtLjN6TTExIDQwVjMwaDYuOXYxLjdoLTQuOFYzNGg0LjJ2MS43aC00LjJWNDBIMTF6Ii8+PC9zdmc+);
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.InputImmatriculation__fieldGroup__wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.InputImmatriculation__part1,
.InputImmatriculation__part2,
.InputImmatriculation__part3 {
  @include clear-input();

  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 900;

  &::placeholder {
    @include placeholder();
    font-weight: 900;
  }
}

.InputImmatriculation__tiret {
  position: relative;
  display: inline-block;
  width: 0;
  transform: translateX(-5px);
}

.InputImmatriculation__part1 {
  width: 100%;
}

.InputImmatriculation__part2 {
  width: 100%;
}

.InputImmatriculation__part3 {
  width: 100%;
}
