@import "styles/colors.scss";
@import 'styles/layout.scss';

.rounded-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $color-black;
  background-color: white;
  border-radius: 10px;
  width: 100%;

  &.primary {
  background-color: var(--color-primary-transparent);
  color: var(--color-primary);

    &.border {
      border-color: var(--color-primary);
    }
  }

  &.secondary {
    background-color: var(--color-secondary-light);
    color: var(--color-secondary);

    &.border {
      border-color: var(--color-secondary);
    }
  }

  &.tertiary {
    color: $bleu-light-opac;
    background-color: $bleu-light-transp;
    border: none;
  }

  &.border {
    border: 3px $grey-light solid;
  }

  &.padding {
    padding: 18px 24px;
  }

  &.no-center {justify-content: flex-start;
    align-items: flex-start;
  }

  &.margin {
    margin: 32px 0;
  }

  &.max-width {
    max-width: $max-input-size;
  }
}