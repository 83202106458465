.app-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-loader-enter-done {
  opacity: 1;
}
.app-loader-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.app-loader-exit-done {
  opacity: 0;
  display: none;
}
.app-loader-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
