.row-actions{

    .bloc-conseil-quotes{
        width: 100%;  
        box-sizing: border-box;


        &.columns-1 {
            max-width: calc(30%);
            margin-right: 70%;
        }
        &.columns-2 {
            max-width: calc(60% + 16px);
       
        }
        &.columns-3 {
            max-width: calc(90% + 32px);
      
        }
    }

}

