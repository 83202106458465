.formMandatSepaFooter {
  p {
    color: #82828c;
    font-size: 12px;
    &.formMandatSepaFooter__title {
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 48px;
    }
    &.formMandatSepaFooter__text {
      font-weight: normal;
      margin-top: 0;
    }
    &.formMandatSepaFooter__coord {
      color: var(--gp-noir, #181822);
      font-weight: normal;
      margin-top: 0;
    }
  }
}
