@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";

@mixin stylingFormAdhesionGlobal($font-main-title, $font-large, $font-subtitle, $font-regular,  $font-small) {

// FONTS

.form-font-title {
  font-size: $font-main-title;
  margin-bottom: 30px;
}

.form-font-large {
  font-size: $font-large;
}

.form-font-regular {
  font-size: $font-regular;
}

.form-font-small {
  font-size: $font-small;
}

.form-font-smaller{
  font-size: $font-subtitle;
}

.form-adhesion-next-button {
  line-height: $font-regular;

  &.margin-top {
    margin-top: 32px;
  }
}

}

.form-adhesion-next-button {
  padding: 10px 30px;
  font-family:"Lato";
  border: 2px var(--color-primary) solid;
  height: 52px;
  border-radius: 24px;
  font-weight: 700;
  min-width: 175px;

  background-color: var(--color-primary);
  color: #fff;
  margin: 16px 0 0;
  cursor: pointer;
  &:active {
    background-color: var(--color-primary-dark);
  }
  &:disabled {
    background-color: $color-disabled;
    border-color: $color-disabled;
    color: white;
    cursor: default;
  }
  &.secondary {
    border-color:  var(--color-primary);
    background-color: white;
    color: var(--color-primary);
  }

  @media (max-width: $breakpoint-small) {
    justify-content: center;
  }
}


.form-adhesion{

@include stylingFormAdhesionGlobal($font-main-title: 32px,  $font-large: 24px, $font-regular:18px, $font-small:16px, $font-subtitle:14px);

  @media screen and (max-width:$breakpoint-large-xl) {

    @include stylingFormAdhesionGlobal($font-main-title: 30px, $font-large: 22px, $font-regular:16px, $font-small:15px, $font-subtitle:14px,);
  }

  @media screen and (max-width: $breakpoint-large-m) {
    @include stylingFormAdhesionGlobal($font-main-title: 29px, $font-large: 19px, $font-regular:16px, $font-small:15px, $font-subtitle:13px,);

  }

  @media screen and (max-width: $breakpoint-large) {
    @include stylingFormAdhesionGlobal($font-main-title: 28px, $font-large: 18px, $font-regular:16px, $font-small:15px, $font-subtitle:13px);

  }

  @media screen and (max-width: $breakpoint-medium-m) {
    @include stylingFormAdhesionGlobal($font-main-title: 26px, $font-large: 17px, $font-regular:15px, $font-small:14px, $font-subtitle:13px);

  }

  @media screen and (max-width: $breakpoint-small) {
    @include stylingFormAdhesionGlobal($font-main-title: 24px, $font-large: 16px, $font-regular:14px, $font-small:13px, $font-subtitle:12px);
    padding: 0;
  }


  .bold {
    font-weight: 900;
  }

  h1, h2, h3 {
    font-family: "Lato";
  }
}