@import "styles/breakpoints.scss";

#right-pane {
  margin-left: 500px;
  padding: 64px 112px;
  @media (max-width: $breakpoint-medium-s) {
    padding: 64px 32px;
    margin-left: 250px;
  }
  @media (max-width: $breakpoint-large-s) {
    padding: 64px 32px;
  }

  @media (max-width: $breakpoint-large-m) {
    padding: 64px;
  }
  height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;

  .fiche-download-right {
    padding: 60px 0 15px 0;
    a {
      color: var(--color-primary);
      }
    }

  @media (max-width: $breakpoint-large) {
    margin-left: 300px;
    padding: 32px;

  }
  @media (max-width: $breakpoint-medium) {
    margin-left: 0;
    padding: 16px;

  }

  @media (min-width: $breakpoint-medium) {
    .fiche-download-right {
      display:none;
    }
  }

  & .mobile-info {
    @media (min-width: $breakpoint-medium) {
      display: none;
    }
  }
}
