.formResiliationDemande {
  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 48px;
  }

  .intro {
    font-weight: bold;
    font-size: 18px;
  }
}
.formResiliationDemandeFooterCoord {
  p {
    font-size: 12px;
    color: var(--gp-noir, #181822);
    font-weight: normal;
    margin-top: 0;

  }
}
.formResiliationResiliationFooterCoord {
  p {
    font-size: 12px;
    color: var(--gp-noir, #181822);
    font-weight: normal;
    margin-top: 0;

  }
}
