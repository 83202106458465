@import "../../styles/colors.scss";
@import "../../styles/breakpoints";

.adhesion-step-pj {

  margin : 50px 16px;

  h3 {
    margin: 16px 0;
  }

.file-input-container{
  margin: 50px 0;
  width: 50%;
  min-width: 400px;
  padding-bottom: 12px;

  @media (max-width: $breakpoint-medium-s) {
    width: 75%;
    min-width: 0;

  }

  @media (max-width: $breakpoint-small) {
    width: 100%;
    margin: 30px 0;
  }


  .file-input {
    font-family: 'Lato';
  }
}

    .error-label {
      color: $color-error;
      float: left;
      margin: 0;
    }
  

  .input-container{
    margin: 12px 0;
    width: 50%;
    min-width: 400px;
    font-family: 'Lato';
    font-weight: 400;
    .error-label {
      float: right;
      color : $color-error;
      margin-top: -8px;
    }


    @media (max-width: $breakpoint-medium-s) {
      width: 75%;
      min-width: 0;
  
    }
  
    @media (max-width: $breakpoint-small) {
      width: 100%;
      margin: 30px 0;
    }

  }

  .label-pj {
    font-weight: normal;
    font-family: "Lato";
    font-weight: 400;
    padding: 12px 0;
    display: block;
  }

  .form-consentement-container{
    margin: 40px 0 8px 0;
  }

  .next-button-container{
    width: 50%;
    display: flex;
    margin: 0;

    @media (max-width: $breakpoint-medium-s) {
      width: 75%;
      min-width: 0;
  
    }
  
    @media (max-width: $breakpoint-small) {
      width: 100%;
      margin: 30px 0;
      justify-content: center;

    }
  }

}
