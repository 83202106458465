@import "styles/colors.scss";
@import "styles/layout.scss";

.input-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  .pin {
    font-size: 12px;
    margin-left: 0;
    margin-right: 8px;
    color: var(--color-primary);
  }
  input {
    opacity: 0;
    position: absolute;
  }
  &:not(:last-of-type) {
    margin-right: 32px;
  }
  &:active {
    outline: none;
  }
  &:focus,
  &:focus .pin {
    color: var(--color-primary);
  }
}
.input-radio-root {
  max-width: $max-input-size;
}
.input-radio-group {
  display: flex;
  margin-bottom: 16px;
}
.input-error,
.input-error .pin {
  color: $color-error;
}
