@import 'styles/layout.scss';

.inline-inputs {
  width: 100%;
}

.miseEnRelation {
  .dureeSejour {
    width: 100%;
    max-width: $max-input-size;

    .container-radio-button-inline {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
