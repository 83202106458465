@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/field.scss';

.inputNumber {
  @include groupField();
}

.inputNumber__label {
  @include label();
}

.inputNumber__tooltip {
  margin-left: 8px;
}

.inputNumber__field {
  @include field();
}
