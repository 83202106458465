.jumbotron {
  padding: 32px;
  background-color: $color-light-background;
  border-radius: 16px;
}
.jumbotron.error {
  background-color: $color-light-error-background;
}

.de-emphasis {
  font-weight: lighter;
  font-style: oblique;
  font-size: 0.9em;
}

.form-link {
  margin-top: 16px;
  &:not(:last-of-type) {
    margin-right: 32px;
  }
}
