@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.form-next-button {
  position: relative;

  .button-container {
    display: flex;
    align-items: center;
  }

  .spinner-container {
    margin: -7px 5px -7px -5px;
    width: fit-content;
  }
  .button-content {
    width: fit-content;
    margin: auto;
  }
}
