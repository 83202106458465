.formResiliationConfirmation {
  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 48px;
  }

  .consentement {
    padding-bottom: 0;
  }

  .intro {
    font-weight: normal;
    font-size: 16px;
  }

}

.formResiliationConfirmationFooterCoord {
  p {
    font-size: 12px;
    color: var(--gp-noir, #181822);
    font-weight: normal;
    margin-top: 0;

  }
}
