@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.FormGenericLink {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  color: var(--color-primary) !important;
  padding: 0 !important;
  font-weight: 300 !important;
}

.FormGenericLink__chevron {
  font-size: 12px;
  margin-left: 0.5rem;
}
