@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/field.scss';

.inputTextarea {
  @include groupField();
}

.inputTextarea__label {
  @include label();
}

.inputTextarea__field {
  @include field();

  width: 100%;
}
