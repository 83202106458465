.priseContactExpress {
  max-width: 700px;

  &--three-quarter {
    width: calc(75% + var(--col-gap));
    max-width: calc(100% + var(--col-gap));
  }

  .input-radio-button {
    width: 10em;
  }

  .input-radio-button.selected {
    border-color: var(--color-primary) !important;
    background: #009ee21a 0% 0% no-repeat padding-box;
    color: black;
  }

  .input-text input,
  .input-radio-button {
    border: 1px solid var(--gris-accessible-composants);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #92939b;
    border-radius: 6px;
    opacity: 1;
    width: 100%;
  }

  .dureeSejour {
    width: 100%;
  }

  .input-text {
    width: 100%;
    max-width: 100%;
    margin-right: 0%;
  }

  .indicatifPays label {
    margin-top: 3%;
    margin-bottom: 2%;
    font-size: 10px;
  }

  .input-text-area {
    max-width: 100%;
  }

  h2 .text-colored {
    color: var(--color-secondary);
  }

  .votreMessage {
    margin-top: 3%;
  }

  small {
    text-align: justify;
  }

  &__row {
    margin-bottom: 2%;

    &--half {
      width: 61% !important;
    }
  }
}
