@import "../../styles/breakpoints.scss";

.list-documents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  font-family: "Lato";


  &.__right-on-mobile {
    justify-content: center;
    display: flex;
    padding-left: 20px;

    @media screen and (max-width: $breakpoint-small) {
      padding-left: 10px;

    }


    .link-with-icon{
      font-family: "Open Sans";
      cursor: pointer;
      margin: 3px 0;
        }
  }
}