@import "styles/breakpoints";
@import "styles/colors";

.app-failure > div {
  margin: 32px;
}

@media (max-width: $breakpoint-medium) {
  .app-failure {
    background-color: $color-light-error-background;
    word-break: break-word;
  }
  .app-failure > div {
    width: 100%;
  }
}
