@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputDateSelect {
  @include groupField();

  max-width: $max-input-size;
}

.InputDateSelect__label {
  @include label();
}

.InputDateSelect__fieldGroup {
  // @include field();

  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  // padding-left: 5px;
  // padding-right: 5px;

  // padding-top: 5px;
  // padding-bottom: 5px;
}

.InputDateSelect__month,
.InputDateSelect__day {
  @include field();

  // box-shadow: none !important;
  // border: none !important;
  // padding: 5px 20px;
  // transition: background-color 0.2s ease;

  // &:hover {
  //   background-color: #f6f6f6;
  // }
}

.InputDateSelect__month__field,
.InputDateSelect__day__field {
  @include clear-input();

  width: 100%;
  // text-align: center;
  // padding-top: 12px;
  // padding-bottom: 12px;

  &[data-placeholder='true'] {
    @include placeholder();
  }

  &.-error {
    @include inputError();
  }
}

// .InputDateSelect__day {
//   width: 65%;
// }

// .InputDateSelect__month__field {
//   width: 65%;
// }

// .InputDateSelect__day {
//   width: 100%;
// }

.InputDateSelect__chevron {
  position: absolute;
  pointer-events: none;
  right: 10px;
}
