.inputCheckBoxBoolean__tooltip {
  margin-left: 8px;
}

.input-checkbox-boolean-root {
  padding-bottom: 24px;
}

.input-checkbox-boolean-label {
  display: flex;
  cursor: pointer;
  margin-bottom: 8px;
}

.input-checkbox-boolean-input-wrapper {
  position: relative;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 2px 8px 2px 2px;
  color: var(--color-primary);

  & > input {
    position: absolute;
    opacity: 0;
    width: 13px;
    height: 13px;
    margin: 0;
  }
}
