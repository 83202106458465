@import './colors.scss';
@import './layout.scss';
@import './blocs.scss';

*:not(svg),
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-black;
  overflow-y: hidden;
}

input,
textarea {
  font-family: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 {
  margin: 0;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 40px;

  span {
    color: var(--color-secondary);
  }
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
  span {
    color: var(--color-secondary);
  }
}
h3 {
  span {
    color: var(--color-secondary);
  }
}

p {
  font-size: 16px;
}

button:not(.no-default-style) {
  padding: 14px 48px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  background-color: var(--color-primary);
  color: #fff;
  margin: 32px 0 0;
  cursor: pointer;
  &:active {
    background-color: var(--color-primary-dark);
  }
  &:focus {
    outline: 2px solid black;
  }
  &.secondary {
    border-color: var(--color-primary);
    background-color: white;
    color: var(--color-primary);
  }
  &:disabled {
    background: $color-disabled;
    cursor: default;
  }
}
