.form-grid {
  --col-gap: 16px;

  &--medium {
    --row-gap: 24px;
  }

  &--large {
    --row-gap: 64px;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--col-gap);
  box-sizing: border-box;

  &__action {
    margin-top: 48px;
  }

  &--large &__bloc {
    margin-top: 48px;
  }

  &--medium &__bloc {
    margin-top: var(--row-gap);
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;

    margin-right: calc(-1 * var(--col-gap));
    margin-bottom: calc(-1 * var(--row-gap));

    &--full {
      width: calc(100% + var(--col-gap));
      max-width: calc(100% + var(--col-gap));
    }

    &--two-third {
      width: calc(66% + var(--col-gap));
      max-width: calc(100% + var(--col-gap));
    }

    &--half {
      width: calc(50% + var(--col-gap));
      max-width: calc(100% + var(--col-gap));
    }

    & + & {
      margin-top: var(--row-gap);
    }

    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;

      &.-two-third,
      &.-half {
        width: calc(100% + var(--col-gap));
      }
    }
  }

  &__field {
    max-width: calc(100% - var(--col-gap));
    margin-right: var(--col-gap);
    margin-bottom: var(--row-gap);

    &--full {
      flex-basis: 100%;
    }

    &--three-quarter {
      flex-basis: calc(75% - var(--col-gap));
    }

    &--half {
      flex-basis: calc(50% - var(--col-gap));
    }

    &--one-third {
      flex-basis: calc(33% - var(--col-gap));
    }

    &--quarter {
      flex-basis: calc(25% - var(--col-gap));
    }

    @media screen and (max-width: 1000px) {
      &--three-quarter {
        flex-basis: 100%;
      }

      &--half {
        flex-basis: 100%;
      }

      &--quarter {
        flex-basis: 100%;
      }
    }
  }
}
