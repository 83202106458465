@import "../../styles/colors.scss";

.field-upload-file {
  background-color: white;
  display: flex;
  flex-direction: column;

  & label {
    cursor: pointer;
  }

  &__input {
    color: var(--color-primary);
    border: 1px dashed var(--color-primary);
    text-align: center;
    margin: 12px 0;
    background-color: var(--color-primary-transparent);
    padding: 16px;
    border-radius: 16px;    cursor: pointer;
    font-weight: 900;
  }

  &__uploaded {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;

    color: var(--color-primary);
    border: 1px solid var(--color-primary-light);
    margin: 12px 0;
    background-color: transparent;
    border-radius: 16px;

    & .icon-preview {
      color : var(--color-primary);
      cursor: pointer;
    }

    & .icon-delete {
      color : $color-error;
      cursor: pointer;
    }

    & .icon-success {
      color : $faux-noir;
      cursor: pointer;
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;

      u {
        text-underline-offset: 2px;
        text-decoration-thickness: 2px;
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
    }
  }

  & p {
    margin: 0;
  }

  & svg {
    margin-right: 8px;
  }

  & > .error-label {
    margin-top: -8px;
    color : $color-error;
  }
}
