@import 'styles/breakpoints';
@import 'styles/colors';

.pane {
  position: fixed;
  z-index: 3;
  padding-left: 18px;
  padding-right: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.56, 1);
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    padding: 0 8px;
  }
  &.opened {
    transform: translateX(0px);
  }
  &.closed {
    transform: translateX(calc(100% + 96px));
  }
  &.failed {
    background-color: $color-error;
    color: #fff;
  }
  &.ongoing {
    background-color: #fff;
    box-shadow: inset 10px 0px 0px 0px var(--color-primary);
  }
  &.succeeded {
    background-color: var(--color-primary);
    color: #fff;
  }

  .link-courtier {
    width: 100%;
    & a {
      text-decoration: none;
      color: white;
      display: inline-block;
      top: 80px;
      left: 80px;
      position: absolute;
    }
  }
}
.error-prompt {
  flex-basis: 100%;
  flex-direction: column;
  padding: 0 96px;
  h1 {
    margin-bottom: 32px;
    text-align: center;
  }
  button {
    color: $color-error;
    background-color: #fff;
    &:active {
      background-color: $color-light-error-background;
    }
  }
}
.success-prompt {
  flex-basis: 100%;
  flex-direction: column;
  padding: 0 96px;
  .success-title,
  .success-title * {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 32px;
    line-height: 38px;
  }
  .success-subtitle,
  .success-subtitle * {
    font-size: 16px;
  }
}
.success-icon {
  font-size: 96px;
  margin-bottom: 32px;
}

.success-baseline {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  position: relative;
  bottom: 0;
  margin-bottom: 16px;
  .success-nom-commercial,
  .success-nom-commercial * {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .sucess-baseline-text,
  .sucess-baseline-text * {
    font-size: 16px;
  }
}
