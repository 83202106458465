@import '../../styles/colors.scss';
@import '../../styles/breakpoints';

.bars-level {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  &__bar {
    height: 7px;
    border-radius: 3px;
    min-width: 40px;
    background-color: $grey-light;
    margin: 0 8px;
    box-shadow: none;

    @media screen and (max-width: $breakpoint-large-m) {
      min-width: 25px;
    }

    @media screen and (max-width: $breakpoint-medium-s) {
      min-width: 20px;
    }

    @media screen and (max-width: $breakpoint-small) {
      min-width: 30px;
    }

    &.selected {
      background-color: var(--color-secondary);
      box-shadow: none;
    }
  }
}
