@import 'styles/colors.scss';
@import 'styles/layout.scss';

.InputRadio {
}

.InputRadio__button {
  max-width: $max-input-size;
  cursor: pointer;
  box-sizing: border-box;
  padding: 16px 24px;
  margin-bottom: 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  display: block;
  &:hover {
    box-shadow: 0 0 0 1px var(--color-primary);
    color: var(--color-primary);
  }
  &:active {
    background-color: var(--color-primary-extra-light);
  }
  &:focus:not(:active) {
    outline: 2px solid black;
  }
  & > input {
    opacity: 0;
    position: absolute;
  }
}
.input-error {
  color: $color-error;
  input {
    border-color: $color-error;
  }
  label {
    border-color: $color-error;
  }
}
