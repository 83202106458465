.formResiliationFooter {
  p {
    color: #82828c;
    font-size: 12px;
    &.formResiliationFooter__title {
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 48px;
    }
    &.formResiliationFooter__text {
      font-weight: normal;
      margin-top: 0;
    }
  }
}
