.formMandatSepaVosCoordonneesBancaires {
  .title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 48px;
  }

  .intro {
    font-weight: bold;
    font-size: 18px;
  }
}
