@import 'styles/colors.scss';
@import 'styles/layout.scss';

.header-enfant-bloc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & > .title-enfant {
    margin-bottom: 8px;
  }
}

.actions-enfant {
  width: auto;
  color: $color-delete;
  margin-bottom: 8px;
  margin-left: 16px;
  &:hover {
    color: $color-delete-hover;
  }
}

.enfant-fields-bloc {
  width: 100%;
  max-width: $max-input-size;
  margin-bottom: 32px;
  border: solid 1px $grey-border-light;
  border-radius: 6px;
  padding: 20px;
}
