@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

$max-input-size: 555px;

hr {
  border: 0;
  border-top: 1px solid $grey-border-light;
  margin: 0;
  margin-bottom: 20px;
}

.layout-container {
  width: 100%;
  display: flex;
}
.layout-full-screen {
  height: 100vh;
  width: 100vw;
}
.layout-centered-content {
  align-items: center;
  justify-content: center;
}
.layout-field-container {
  width: 100%;
  display: flex;
  max-width: $max-input-size;
  & > * {
    flex-basis: 100%;
  }
  & > *:not(:first-of-type) {
    margin-left: 16px;
  }
  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
    & > *:not(:first-of-type) {
      margin-left: 0;
    }
  }
}
.layout-field-container-half {
  width: 100%;
  display: flex;
  max-width: $max-input-size;
  & > * {
    flex-basis: 50%;
  }
  & > *:not(:first-of-type) {
    margin-left: 16px;
  }
  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
    & > *:not(:first-of-type) {
      margin-left: 0;
    }
  }
}

.layout-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: $max-input-size;
}

.layout-button-container-responsive {
  width: 100%;
  display: flex;
  gap: 20px;
  max-width: $max-input-size;

  @media (max-width: $breakpoint-small) {
    flex-direction: column;
  }
}

.layout-2-col {
  width: 100%;
  display: flex;
  max-width: $max-input-size;
  gap: 16px;

  & > * {
    flex: 0 1 100%;
    width: 100%;
  }

  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.layout-flex-basis-auto {
  flex-basis: auto;
}
