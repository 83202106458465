@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputSlider {
  @include groupField();
}

.InputSlider__label {
  @include label();
}

.InputSlider__fieldGroup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
}
.rc-tooltip {
  z-index: 0;
}

.rc-slider-handle {
  background-color: #fff !important;
  border: none !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25) !important;

  width: 24px !important;
  height: 24px !important;
  margin-top: -10px !important;
}
