@import '../../styles/colors.scss';

.bloc-conseil-quotes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 20px;
  font-weight: 400;
  color: var(--color-secondary);
  flex-wrap: wrap;
  border: 1px solid var(--color-secondary);
  border-radius: 10px;

  & h2 {
    display: inline-flex;
    align-items: center;
    flex-basis: 100%;
    margin: 8px 0;
    & svg {
      margin-right: 8px;
    }
  }

  & p {
    color: $faux-noir;
  }

  & .bloc-conseil-quotes__text {
    width: 100%;
  }
}
