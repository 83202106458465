.numeroAdherent__solo {
  margin-bottom: 32px;
  max-width: calc(50% - 8px);
  position: relative;
  .numeroAdherent__input {
    margin-bottom: 0;
  }

  .numeroAdherent__remove {
    position: absolute;
    right: 0;
    top: 0;
  }

  .inputText__label {
    margin-bottom: 15px;
  }
}
