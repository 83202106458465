.formMandatSepaVotreRIB {
  .formMandatSepaVotreRIB__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 48px;
  }

  .formMandatSepaVotreRIB__text {
    margin-top: 0;
    margin-bottom: 48px;
  }
}
