@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/field.scss';

.InputSelect {
  @include groupField();
}

.InputSelect__label {
  @include label();
}

.inputSelect__tooltip {
  margin-left: 8px;
}

.InputSelect__container {
  .InputSelect__placeholder {
    @include placeholder();
  }

  .InputSelect__control {
    @include field();
  }

  .InputSelect__value-container,
  .InputSelect__value-container * {
    margin: 0;
    padding: 0;
  }

  .InputSelect__control--is-focused {
    @include focus();
  }

  .InputSelect__indicator-separator {
    display: none;
  }

  .InputSelect__indicator {
    color: $faux-noir;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.-error {
  .InputSelect__control,
  .InputSelect__indicator {
    @include inputError();
  }
}

.InputSelect__menu {
  .InputSelect__option {
    cursor: pointer;
    color: $color-black;

    &.InputSelect__option--is-selected {
      background-color: var(--color-primary-transparent);
      color: $color-black;
    }

    &.InputSelect__option--is-focused {
      background-color: var(--color-primary);
      color: white;
    }

    &.InputSelect__option--is-focused.InputSelect__option--is-selected {
      background-color: var(--color-primary);
      color: white;
    }

    &:active {
      background-color: var(--color-primary);
      color: white;
    }
  }
}

.InputSelect__clear {
  position: absolute;
  width: 12px;
  cursor: pointer;
  top: 13px;
  right: 24px;
}
