.adhesion-step-frais{
  margin : 50px 16px;
  
  .marged-block {
    margin-left: 28px;
  }

  .stripeCb{
    width: 60%;
  }

}