@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.FormGenericButton {
  background-color: var(--color-primary-alpha) !important;
  color: var(--color-primary) !important;

  .button-container {
    display: flex;
    align-items: center;
  }

  .spinner-container {
    width: fit-content;
  }
  .button-content {
    width: fit-content;
    margin: auto;
  }
}
