@import "styles/colors";
@import "styles/breakpoints.scss";

.link-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin: 10px 0;
  color : $grey;
  text-decoration: none;
  

  @media (max-width: $breakpoint-small) {
    font-size: 12px;
  }
  

  & svg {
    font-size: 12px;
    padding: 8px;
  }

  &.primary {
    color : var(--color-primary);
  }

  &.secondary {
      color: var(--color-secondary);
  }
}

.text-colored {
  color : var(--color-secondary);
}