@import 'styles/colors.scss';
@import 'styles/layout.scss';
@import 'styles/breakpoints.scss';
@import 'styles/field.scss';

.InputRadioInline {
  @include groupField();
}

.InputRadioInline__label {
  @include label();

  position: relative;
}

.InputRadioInline__container {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
}

.InputRadioInline__group {
  display: block;
  width: auto;

  &.-fullWidthLabel {
    width: 100%;
  }

  &.-column-1 {
    width: 100%;
  }

  &.-column-2 {
    width: calc(50% - 7px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &.-column-3 {
    width: calc(33.33% - 10px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }

  &.-column-4 {
    min-width: 0px;
    width: calc(25% - 11px);

    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
}

.InputRadioInline__button {
  @include field();

  display: block;
  cursor: pointer;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 0px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.-noCenterLabel {
    text-align: left;
  }

  .InputRadioInline__input:checked:not(:disabled) + & {
    @include checked();
  }

  .InputRadioInline__input:disabled + & {
    @include disabled();
    opacity: 0.4;
  }
  .InputRadioInline__input:disabled:checked + & {
    opacity: 1;
  }
}

.InputRadioInline__checkmark {
  display: none;
  position: absolute;
  margin-left: 7px;
  width: 10px !important;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);

  path {
    fill: var(--color-primary);
  }

  .InputRadioInline__input:checked + .InputRadioInline__button & {
    display: inline-block;
  }

  .InputRadioInline__input:disabled + .InputRadioInline__button & {
    path {
      fill: $color-grey-medium !important;
    }
  }
}

.InputRadioInline__input {
  position: absolute;
  opacity: 0;
}
