@import 'styles/colors.scss';
@import 'styles/breakpoints.scss';

.prev-button-wrapper {
  display: flex;
  align-items: center;
  margin: 24px 0px 34px -5px;
  height: 10px;
}
.prev-button {
  padding: 4px;
  font-size: 16px;
  font-weight: normal;
  background-color: transparent;
  outline: none;
  border: none;
  color: $color-black;
  cursor: pointer;
  &:active {
    color: var(--color-primary);
    background-color: transparent;
  }
  &:focus {
    outline: 2px solid black;
  }
  & > span {
    margin-left: 8px;
  }
}
